import GlobalStyles from '@mui/material/GlobalStyles';
const BrandStyle = ({ appTheme }) => {
    // console.log(appTheme)
    return (
        <GlobalStyles
        styles={{
            ":root": {
                "--color-primary": appTheme.palette.primary.main,
                "--color-primary-text": appTheme.palette.primary.contrastText,
                "--color-secondary": appTheme.palette.secondary.main,
                "--color-secondary-text": appTheme.palette.secondary.contrastText,
                'fontFamily': 'Poppins, sans-serif', //TODO: universal setting
                // marginLeft: isMobile ? '13rem' : 'unset',
                // marginRight: isMobile ? '13rem' : 'unset',
            },
   
        }}
        
    />
    );
};

export default BrandStyle;
